import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';

// component ll categories slider lw7do w h5do a7to fl mkan l ana 3awzo 

export default function CategoriesSlider() {

    var settings = {  // dah 5as bl react slick slider 
        dots:true,
        infinite: true,
        speed:2000,
        slidesToShow: 6,
        slidesToScroll:2,
        arrows:false,
        autoplay:true,
        autoplaySpeed:2500,
        responsive:[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
    };

    let [categories , setCategories] = useState([]);

    async function getAllCategories(){
        let request = await axios.get('https://ecommerce.routemisr.com/api/v1/categories');
        setCategories(request.data.data);
    }

    useEffect(()=>{
      getAllCategories()
    },[])

   return <>
    <h4 className='mt-5 mb-3'>Shop Popular Categories</h4>

    <Slider {...settings}>
         
       {categories.map((el)=> 
            <div key={el._id} className='px-1 cursor-pointer'>
              <Link to={"/categories"}>
                <img src={el.image} alt="CategoryImage" className='w-100 imgHight2 rounded-5'/>
                <h6 className='mt-3 text-center text-main'>{el.name}</h6>
              </Link>
            </div>)
        }     

    </Slider>
  
  </>
    

}
