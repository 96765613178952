import axios from 'axios';
import { useFormik } from 'formik' ;
import React, { useContext, useState } from 'react' ;
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup' ;
import { authContext } from '../../Contexts/AuthContext';


export default function Login () {

  const {setUserIsLoggedIn} = useContext(authContext); // destruct l data l fl context l ana 3mlto 

  let [errorMessage , setError] = useState(""); // dah l variable l hrg3 a3rd kymto t7t 
  let [loading , setLoading]= useState(false);
  let navigate = useNavigate();  // 3shan a3ml routing l mkan tani 3n try2 function msh click 

  async function loginSubmit(values)
  { 
    setLoading(true); // l loading button ysht8al

    try {
      let request = await axios.post("https://ecommerce.routemisr.com/api/v1/auth/signin" , values);
      console.log(request.data);
      setError(request.data.message);
      if(request.data.message === "success"){
          setUserIsLoggedIn(true);  // 5znt m3lomt an l user 3amel logged in 
          localStorage.setItem("userToken",request.data.token); // 5znt fl storage al user token 
          navigate("/home")
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }

    setLoading(false); // b3d ma y5ls swa2 error aw success y remove l loading button
  }

  let passRegex = /^[A-Z][a-z0-9]{5,10}$/; 

  let validationSchema = yup.object({

    email:yup.string().email('enter a valid email').required(),
    password:yup.string().matches(passRegex,'use at least 6 characters and maximum 10 characters starting with one uppercase letter').required(),

  })

  let formik = useFormik({

    initialValues:{
    email:"",
    password:"",
    } ,

    validationSchema : validationSchema ,

    onSubmit:loginSubmit

  })


  return (
  
  <div className='w-75 m-auto'>

    <h5 className='mt-4 mb-3 fs-3'>Login Now :</h5>

    <form onSubmit={formik.handleSubmit}> 

        
        <label htmlFor="email">email :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='email' type="text" id='email' className='form-control form-control-sm mb-1' />  

        {formik.errors.email && formik.touched.email?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.email}</div>:""}

        <label htmlFor="password">password :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='password' type="password" id='password' className='form-control form-control-sm mb-1' />  

        {formik.errors.password && formik.touched.password?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.password}</div>:""}

        {errorMessage?<div className='text-center text-danger fw-bolder mb-0 pt-3'>{errorMessage}</div>:""}

        {loading?
          <button disabled type='button' className='btn bg-main mb-4 mt-3 text-white d-block ms-auto'><i className="fas fa-spinner fa-spin px-3"></i></button>
          :
          <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn bg-main mb-4 mt-3 text-white d-block ms-auto px-3'>Login</button>
        }
        
        
        
        
    </form> 

  </div>
  )
  
}
