import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Slider from "react-slick";
import { Slide, ToastContainer, toast } from 'react-toastify';
import { cartContext } from '../../Contexts/CartContext';

export default function ProductDetails() {

  var settings = {  // dah 5as bl react slick slider 
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    autoplay:true,
    autoplaySpeed:2000
  };

  let {id} = useParams(); // 3shan agyb l ID mn l url (w 3amel destructing)
  let [productDetails , setProductDetails] = useState({});
  let [loadingIcon , setLoadingIcon] = useState(true); 
  const {setCartItems} = useContext(cartContext); // destruct l data l fl context l ana 3mlto 


  async function addToCart(id){
    let request = await axios.post('https://ecommerce.routemisr.com/api/v1/cart',{productId:id},{headers:{
      token:localStorage.getItem("userToken")
    }})
    console.log(request.data);
    setCartItems(request.data.numOfCartItems);
    toast.success(request.data.message,{
      autoClose: 4000,
      transition:Slide,
      closeOnClick: true
    });
  }

  async function getProductDetails(){
    setLoadingIcon(true);
    let request = await axios.get('https://ecommerce.routemisr.com/api/v1/products/' + id);
    setProductDetails(request.data.data); // 5znt l data f variable l productDetails
    setLoadingIcon(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=>{getProductDetails()},[]);  // component did mount a7sn mkan anadi l api feh 

  return  <>

    {loadingIcon?<div className='my-5 py-5 justify-content-center align-items-center d-flex'>
      <i className="fa-solid fa-spinner fa-spin text-main fa-3x"></i>
    </div>
    :
    <div className='row align-items-center mt-5 mb-5'>

      <div className="col-md-3 mb-5">

        <Slider {...settings}>
         
          {productDetails.images?.map((image,index)=> 
          <div key={index}>
            <img src={image} alt="productImage" className='w-100'/>
          </div>)}       

        </Slider>

      </div>

      <div className="col-md-9">
        <h2 className='mt-2'>{productDetails?.title}</h2>
        <h5 className='text-main mt-2'>{productDetails?.category?.name}</h5>
        <p className='mt-3 text-secondary'>{productDetails?.description}</p>
        <p className='d-flex justify-content-between mt-2'>
            <span>{productDetails?.price} EGP</span>
            <span>
                <i className='fas fa-star rating-color'></i> {productDetails?.ratingsAverage}
            </span>
        </p>
        <button onClick={()=>addToCart(productDetails?._id)} className='btn bg-main text-white w-100 mt-2'>
          Add To Cart
        </button>
      </div>

    </div>}


    <ToastContainer/>
   
  </>
}
