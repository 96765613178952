// Authentication context for user logedin :- 

import { createContext, useState } from "react";


export let authContext = createContext();

export default function AuthContextProvider({children}){

    let [userIsLoggedIn , setUserIsLoggedIn] = useState(!!localStorage.getItem("userToken")); 
    // lo local storge feha token yb2a l user 3amel loggedin yb2a userIsLoggedIn = true 
    // lo local storge mfyhash token yb2a l usser msh loggedin yb2a userIsloggedIn = flase

    return <authContext.Provider value={ {userIsLoggedIn , setUserIsLoggedIn} }>
        {children}
    </authContext.Provider>
}