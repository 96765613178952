import axios from 'axios';
import { useFormik } from 'formik' ;
import React, { useState } from 'react' ;
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup' ;


export default function Register() {

  let [errorMessage , setError] = useState("");     // dah l variable l hrg3 a3rd kymto t7t 
  let [loading , setLoading]= useState(false);
  let navigate = useNavigate();      // 3shan a3ml routing l mkan tani 3n try2 function msh click 

  async function registerSubmit(values)
  { 
    setLoading(true); // l loading button ysht8al
    try {
      let request = await axios.post("https://ecommerce.routemisr.com/api/v1/auth/signup" , values);
      console.log(request.data);
      setError(request.data.message);
      if(request.data.message === "success"){
          navigate("/login")
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }
    setLoading(false); // b3d ma y5ls swa2 error aw success ytshal l loading button
  }

  let phoneRegex = /^01[0-2,5]{1}[0-9]{8}$/ ; 
  let passRegex = /^[A-Z][a-z0-9]{5,10}$/; 

  let validationSchema = yup.object({
    name:yup.string().min(3,'name must be at least 3 characters').max(10,'name max.Length is 10 characters').required(),
    email:yup.string().email('enter a valid email').required(),
    phone:yup.string().matches(phoneRegex,'enter a valid phone number').required(),
    password:yup.string().matches(passRegex,'use at least 6 characters and maximum 10 characters starting with one uppercase letter').required(),
    rePassword:yup.string().oneOf([yup.ref("password")],'must match the password').required(),
  })

  let formik = useFormik({

    initialValues:{
    name: "",
    email:"",
    password:"",
    rePassword:"",
    phone:""
    } ,

    validationSchema : validationSchema ,

    onSubmit:registerSubmit

  })


  return (
  
  <div className='w-75 m-auto'>

    <h5 className='mt-4 mb-3 fs-3'>Register Now :</h5>

    <form onSubmit={formik.handleSubmit}> 

        <label htmlFor="name">name :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='name' type="text" id='name' className='form-control form-control-sm mb-1'/>

        {formik.errors.name && formik.touched.name?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.name}</div>:""}
        
        <label htmlFor="email">email :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='email' type="text" id='email' className='form-control form-control-sm mb-1' />  

        {formik.errors.email && formik.touched.email?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.email}</div>:""}

        <label htmlFor="password">password :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='password' type="password" id='password' className='form-control form-control-sm mb-1' />  

        {formik.errors.password && formik.touched.password?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.password}</div>:""}

        <label htmlFor="rePassword">rePassword :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='rePassword' type="password" id='rePassword' className='form-control form-control-sm mb-1' />  

        {formik.errors.rePassword && formik.touched.rePassword?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.rePassword}</div>:""}

        <label htmlFor="phone">phone :</label>
        <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='phone' type="tel" id='phone' className='form-control form-control-sm mb-1' />  

        {formik.errors.phone && formik.touched.phone?<div className='alert alert-danger mb-0 ps-3 p-1'>{formik.errors.phone}</div>:""}

        {errorMessage?<div className='text-center text-danger fw-bolder mb-0 pt-3'>{errorMessage}</div>:""}

        {loading?
          <button disabled type='button' className='btn bg-main mb-4 mt-3 px-4 text-white d-block ms-auto'><i className="fas fa-spinner fa-spin px-3"></i></button>
          :
          <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn bg-main mb-4 mt-3 text-white d-block ms-auto px-3'>Register</button>
        }       
        
    </form> 

  </div>
  )
  
}
