import React, { useContext } from 'react'
import { authContext } from '../../Contexts/AuthContext';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({children}) {

 const {userIsLoggedIn} = useContext(authContext); // destruct l data l fl context l ana 3mlto

 return <>

    {userIsLoggedIn? children : <Navigate to={"/login"}/> }  
    {/* lo el user loggedin hy3rd component l child l mt7dd lo l2a hyro7 3l login component*/}

  </>
}
