import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from '../../images/freshcart-logo.svg'
import { authContext } from '../../Contexts/AuthContext';
import { cartContext } from '../../Contexts/CartContext';


export default function Navbar() {

  const {userIsLoggedIn,setUserIsLoggedIn} = useContext(authContext); // destruct l data l fl context l ana 3mlto 
  const {cartItems} = useContext(cartContext); // destruct l data l fl context l ana 3mlto 
  let navigate = useNavigate();  // 3shan a3ml routing l mkan tani 3n try2 function msh click 

  function logOut(){
    setUserIsLoggedIn(false);
    localStorage.removeItem("userToken");
    navigate("/login");
  }
  
  return <>
  
<nav className="navbar navbar-expand-lg bg-main-light">
  <div className="container">
    <NavLink className="navbar-brand" to="home"><img src={logo} alt="fresh Cart logo"/></NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">

      {userIsLoggedIn? <ul className="navbar-nav me-auto mb-2 mb-lg-0">

      <li className="nav-item">
        <NavLink className="nav-link" to="home">Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="cart">Cart</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="allorders">Orders</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="products">Products</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="categories">Categories</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="brands">Brands</NavLink>
      </li>

      </ul> :""}

      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item d-flex justify-content-center align-items-center">
              <i className="fa-brands fa-facebook mx-2"></i>
              <i className="fa-brands fa-twitter mx-2"></i>
              <i className="fa-brands fa-instagram mx-2"></i>
              <i className="fa-brands fa-threads mx-2"></i>
              {userIsLoggedIn?
              <i className="fa-solid fa-cart-shopping ms-3 me-2 text-main mySize position-relative">
                <span className='noSize rounded-2 position-absolute top-0 start-100 translate-middle'>
                  {cartItems}
                </span>
              </i>:""}
          </li>
          {userIsLoggedIn? <li className="nav-item">
            <span onClick={logOut} className="nav-link cursor-pointer fw-bolder">Logout</span>
          </li>:
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="login">Login</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Register</NavLink>
            </li>    
          </>}   
      </ul>

    </div>
  </div>
</nav>
  
  </>
}
