import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import logo from '../../images/freshcart-logo.svg'
import { Link } from 'react-router-dom';
import fix1 from '../../images/fix1.jpeg';
import fix2 from '../../images/fix2.jpeg';
import b1 from '../../images/b1.jpeg';
import b2 from '../../images/b2.jpeg';
import b3 from '../../images/b3.jpeg';
import b4 from '../../images/b4.jpeg';
import b5 from '../../images/b5.jpeg';
import b6 from '../../images/b6.jpeg';
import Slider from "react-slick";
import CategoriesSlider from '../CategoriesSlider/CategoriesSlider';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { cartContext } from '../../Contexts/CartContext';


export default function Home() {

  const {setCartItems , getUserCart} = useContext(cartContext); // destruct l data l fl context l ana 3mlto 
  useEffect(()=>{
    getUserCart()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); // component did mount a7sn mkan anadi l api fe

  async function addToCart(id){
    let request = await axios.post('https://ecommerce.routemisr.com/api/v1/cart',{productId:id},{headers:{
      token:localStorage.getItem("userToken")
    }})
    console.log(request.data);
    setCartItems(request.data.numOfCartItems);
    toast.success(request.data.message,{
      autoClose: 4000,
      transition:Slide,
      closeOnClick: true
    });
  }

  var settings = {  // dah 5as bl react slick slider 
    dots:true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    autoplay:true,
    autoplaySpeed:4000
  };

  let [products , setProducts] = useState([]);
  let [loadingIcon , setLoadingIcon] = useState(true);

  async function getAllProducts(){
    setLoadingIcon(true);

    let request = await axios.get('https://ecommerce.routemisr.com/api/v1/products');
    setProducts(request.data.data); // 5znt l data f variable l products 

    setLoadingIcon(false);
  }

  useEffect(()=>{
    getAllProducts()
  },[]); // component did mount a7sn mkan anadi l api feh 


  return <>

    {loadingIcon?<div className='d-flex justify-content-center align-items-center position-fixed top-0 bottom-0 end-0 start-0 bg-main-light'>
      <div>
        <img src={logo} width={300} alt="freshCart logo" />
        <span className="loader"></span>
      </div>
    </div> 
    : 
    <>
      <div className="mainSlider row mt-5 g-0">
        <div className="col-md-9">
          <Slider {...settings}>
            <img src={b6} className='w-100 rounded-start-4 imgHight' alt="bannerimage1"/> 
            <img src={b1} className='w-100 rounded-start-4 imgHight' alt="bannerimage1"/>  
            <img src={b5} className='w-100 rounded-start-4 imgHight' alt="bannerimage1"/>
            <img src={b2} className='w-100 rounded-start-4 imgHight' alt="bannerimage1"/> 
            <img src={b3} className='w-100 rounded-start-4 imgHight' alt="bannerimage1"/>   
            <img src={b4} className='w-100 rounded-start-4 imgHight' alt="bannerimage1"/> 
          </Slider>
        </div>

        <div className="col-md-3">
          <img src={fix1} className='w-100 imgbord1' alt="bannerimage1"/>
          <img src={fix2} className='w-100 imgbord2' alt="bannerimage2"/>
        </div>
      </div>

      <CategoriesSlider/>

      <div className="row mt-5 mb-0">

        {products.map((el)=> 

          <div key={el.id} className="col-md-3 mb-5">

            <div className="product px-2">
              <Link to={"/productDetails/"+el.id}>
                <img src={el.imageCover} className='w-100' alt="productImage"/>
                <h6 className="text-main mt-3">{el.category.name}</h6>
                <h4>{el.title.split(" ").splice(0,2).join(" ")}</h4>
                <div className='d-flex justify-content-between'>
                  <span>{el.price} EGP</span>
                  <span><i className="fa-solid fa-star rating-color"></i> {el.ratingsAverage}</span>
                </div>
              </Link>
                <button onClick={()=>addToCart(el.id)} className='btn bg-main d-block text-white w-100 my-3'>Add To Cart</button>
            </div>

          </div>)
        }

      </div>
    </>
    }

    <ToastContainer/> 

  </>
  
}
