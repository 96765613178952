import React, { useEffect, useState } from 'react'
import pic from "../../images/empty3.png"
import { jwtDecode } from "jwt-decode";
import axios from 'axios';

export default function Orders() {

  let [loadingIcon , setLoadingIcon] = useState(true); 
  let [emptyOrders , setEmptyOrders] = useState(true);
  let [orders , setOrders] = useState([]);

  const {id} = jwtDecode(localStorage.getItem("userToken")); // id l 3mltlo distruct mn l token b3d fk l tshfer

  async function getUserOrders(){
    setLoadingIcon(true);
    try {
      let request = await axios.get(`https://ecommerce.routemisr.com/api/v1/orders/user/${id}`);
      console.log(request.data);
      setOrders(request.data);
      setEmptyOrders(false);
    } catch (error) {
      console.log(error);
      setEmptyOrders(true);
    }
    setLoadingIcon(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=>{getUserOrders()},[]);

  return <>

    {
    
      loadingIcon? <div className='my-5 py-5 justify-content-center align-items-center d-flex'>
        <i className="fa-solid fa-spinner fa-spin text-main fa-3x"></i>
      </div>

      :

      <>
        {emptyOrders || orders.length === 0 ?<div className='text-center my-4'>
        <img src={pic} alt="emptyCart" width={455}/>
        <p className='p-0 m-0 pb-2 fw-bolder'>NO ORDERS FOUND</p>
        </div>
        :
        <div className='parent my-5'>
          {orders.map((order) =>
            <div key={order.id} className="row shadow mb-5 p-3 m-0 rounded-5">
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className='fw-bolder'># {order.id}</h2>
                  <h4 className='text-main ms-5'>Ordered Successfully <i className="fa-regular fa-circle-check text-main"></i></h4> 
                </div>
                <p>You have ordered {order.cartItems.length} {order.cartItems.length === 1? "item" : "items"}</p>
                <hr/>
                <div className="row g-0">
                  {order.cartItems.map((item) => {
                    return <div key={item._id} className='col-md-3 my-2 text-center position-relative'>
                      <img className='w-75 img-thumbnail ' src={item.product.imageCover}  alt="productImage"/>
                      <p className='pt-2 p-0 m-0 fw-bolder text-secondary'>
                        {item.product.title.split(" ").splice(0,2).join(" ")}
                      </p>
                      <p className='position-absolute ms-1 top-0 p-2 bg-main-light fw-bolder rounded-2'>
                        {item.count}
                      </p>
                    </div> 
                  })}
                </div>
                <hr/>

                <div className="d-flex align-items-center justify-content-between">
                  <p className='fw-bolder p-0 m-0 me-4'>Total Order Price : {order.totalOrderPrice} EGP</p>
                  <div className='p-0 m-0'>
                    <i className="fa-brands fa-fedex me-3 fs-2"></i>
                    <i className="fa-brands fa-dhl me-3 fs-2"></i>
                    <i className="fa-brands fa-stripe me-3 fs-2"></i>
                    <i className="fa-brands fa-google-pay me-3 fs-2"></i>
                    <i className="fa-brands fa-apple-pay me-3 fs-2"></i>
                    <i className="fa-brands fa-ups fs-3"></i>
                  </div>
                </div>
                
              </div>
            </div>
           )}
        </div>  
        }
      </>
      
    }

  </>


}
