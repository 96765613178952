import axios from 'axios'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { cartContext } from '../../Contexts/CartContext';
import { useQuery } from 'react-query';

export default function Products() {

  const {setCartItems} = useContext(cartContext); // destruct l data l fl context l ana 3mlto 

  async function addToCart(id){
    let request = await axios.post('https://ecommerce.routemisr.com/api/v1/cart',{productId:id},{headers:{
      token:localStorage.getItem("userToken")
    }})
    console.log(request.data);
    setCartItems(request.data.numOfCartItems);
    toast.success(request.data.message,{
      autoClose: 4000,
      transition:Slide,
      closeOnClick: true
    });
  }


  function getAllProducts(){

    return axios.get('https://ecommerce.routemisr.com/api/v1/products');

  }

  const {data , isLoading} = useQuery('products' , getAllProducts ,{
    cacheTime : 10000 ,
    staleTime : 5000 , 
    refetchOnMount : false ,
    refetchOnWindowFocus : false 
  });

  console.log(data);
 


  return <>

    {isLoading === true ? <div className='my-5 py-5 justify-content-center align-items-center d-flex'>
        <i className="fa-solid fa-spinner fa-spin text-main fa-3x"></i>
      </div>
      :
      <div className="row mt-5 mb-0">

        {data?.data.data.map((el)=> 

          <div key={el.id} className="col-md-3 mb-5">

            <div className="product px-2">
              <Link to={"/productDetails/"+el.id}>
                <img src={el.imageCover} className='w-100' alt="productImage"/>
                <h6 className="text-main mt-3">{el.category.name}</h6>
                <h4>{el.title.split(" ").splice(0,2).join(" ")}</h4>
                <div className='d-flex justify-content-between'>
                  <span>{el.price} EGP</span>
                  <span><i className="fa-solid fa-star rating-color"></i> {el.ratingsAverage}</span>
                </div>
              </Link>
                <button onClick={()=>addToCart(el.id)} className='btn bg-main d-block text-white w-100 my-3'>Add To Cart</button>
            </div>

          </div>)
        }

      </div>
    }
    
      

    

    <ToastContainer/> 

  </>

}
