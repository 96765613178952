import axios from 'axios';
import { useFormik } from 'formik' ;
import React, {useState } from 'react' ;
import { useParams } from 'react-router-dom';
import * as yup from 'yup' ;

export default function Address() {

    let [errorMessage , setError] = useState(""); // dah l variable l hrg3 a3rd kymto t7t 
    let [loading , setLoading]= useState(false);
    let {cartId} = useParams(); // 3shan agyb l cart ID mn l url (w 3amel destructing)

    async function checkOutSubmit(values){ 

     setLoading(true); // l loading button ysht8al

     try {
       let request = await axios.post(`https://ecommerce.routemisr.com/api/v1/orders/checkout-session/${cartId}`,{
        shippingAddress:values
       },{headers:{token:localStorage.getItem("userToken")},params:{url:'https://freshcart.adhamkaraman.com/#'}});
       console.log(request.data.session.url);
       setError(request.data.status);
       window.open(request.data.session.url,"_self"); // 3shan aft7 link w self 3shan yb2a f nfs l tab 
     } catch (error) {
       console.log(error);
       setError(error.response.data.statusMsg);
     }

     setLoading(false); // b3d ma y5ls swa2 error aw success y remove l loading button
    }

    let phoneRegex = /^01[0-2,5]{1}[0-9]{8}$/ ; 

    let validationSchema = yup.object({

     details:yup.string().required(),
     city:yup.string().required(),
     phone:yup.string().matches(phoneRegex,'enter a valid Egyptian phone number').required()

    })

    let formik = useFormik({

     initialValues:{
     details:"",
     phone:"",
     city:"",
     },

     validationSchema : validationSchema ,

     onSubmit:checkOutSubmit
    })
    
  
  return <>

   <div className='w-75 m-auto'>

    <h5 className='mt-4 mb-3 fs-3'>Shipping Address :</h5>

    <form onSubmit={formik.handleSubmit}> 

    <label htmlFor="details">details :</label>
    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='details' type="text" id='details' className='form-control form-control-sm mb-1' />  

    {formik.errors.details && formik.touched.details?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.details}</div>:""}

    <label htmlFor="city">city :</label>
    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='city' type="text" id='city' className='form-control form-control-sm mb-1' />  

    {formik.errors.city && formik.touched.city?<div className='alert alert-danger p-0 ps-3 p-1'>{formik.errors.city}</div>:""}

    <label htmlFor="phone">phone :</label>
    <input onBlur={formik.handleBlur} onChange={formik.handleChange} name='phone' type="tel" id='phone' className='form-control form-control-sm mb-1' />  

    {formik.errors.phone && formik.touched.phone?<div className='alert alert-danger mb-0 ps-3 p-1'>{formik.errors.phone}</div>:""}

    {errorMessage?<div className='text-center text-danger fw-bolder mb-0 pt-3'>{errorMessage}</div>:""}

    {loading?
      <button disabled type='button' className='btn bg-main mb-4 mt-3 px-4 text-white d-block ms-auto'><i className="fas fa-spinner fa-spin px-3"></i></button>
      :
      <button disabled={!(formik.isValid && formik.dirty)} type='submit' className='btn bg-main mb-4 mt-3 text-white d-block ms-auto px-3'>Check Out</button>
    }
     
    </form> 

  </div>

</>

}
