// Cart context for sharing cart items with navbar :-

import axios from "axios";
import {createContext, useEffect, useState} from "react";


export let cartContext = createContext();

export default function CartContextProvider({children}){

    let [cartItems , setCartItems] = useState(0);
    
    async function getUserCart(){
        try {
          let request = await axios.get('https://ecommerce.routemisr.com/api/v1/cart',{headers:
          {token:localStorage.getItem("userToken")}});
          setCartItems(request.data.numOfCartItems);
          console.log('USER CART IS CALLED');
        } catch (error) {
          console.log(error.response.data.message);
          setCartItems(0);
        }
    }

    useEffect(()=>{
        getUserCart()
    },[]); // component did mount a7sn mkan anadi l api feh 
    

    return <cartContext.Provider value={{cartItems , setCartItems , getUserCart}}>
        {children}
    </cartContext.Provider>
}
