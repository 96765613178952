import axios from 'axios'
import React from 'react'
import { useQuery } from 'react-query';


export default function Brands() {


  function getAllBrands(){
    return axios.get('https://ecommerce.routemisr.com/api/v1/brands');
  }

  const {data , isLoading} = useQuery('brands' , getAllBrands , {
    cacheTime : 5000 ,
    staleTime : 5000 , 
    refetchOnMount : false ,
    refetchOnWindowFocus : false
  });

  console.log(data);

  return <>
  
    {isLoading === true ? <div className='my-5 py-5 justify-content-center align-items-center d-flex'>
        <i className="fa-solid fa-spinner fa-spin text-main fa-3x"></i>
      </div>

      :

      <div className="row mt-5 mb-5">

        {data?.data.data.map((el)=> 

          <div key={el.id} className="col-md-3 mb-3">

            <div className="border border-4 border-secondary-subtle">
                <img src={el.image} className='w-100' alt="productImage"/>
            </div>

          </div>)
        }

      </div>
    }
  
  </>
  
}
