import React from 'react'
import { RouterProvider, createHashRouter } from 'react-router-dom'

import Layout from './Components/Layout/Layout'
import Home from './Components/Home/Home'
import Cart from './Components/Cart/Cart'
import Products from './Components/Products/Products'
import Categories from './Components/Categories/Categories'
import Brands from './Components/Brands/Brands'
import Login from './Components/Login/Login'
import Notfound from './Components/Notfound/Notfound'
import Register from './Components/Register/Register'
import AuthContextProvider from './Contexts/AuthContext'
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute'
import ProtectedLog from './Components/ProtectedRoute/ProtectedLog'
import ProductDetails from './Components/ProductDetails/ProductDetails'
import Orders from './Components/Orders/Orders'
import Address from './Components/Address/Address'
import CartContextProvider from './Contexts/CartContext'

import { QueryClient , QueryClientProvider } from 'react-query' 
import { ReactQueryDevtools } from 'react-query/devtools' 
import { Provider } from 'react-redux'
import { store } from './Redux/Store'





let routers = createHashRouter([
  {path:"" , element:<Layout/> , children:[
    {path:"home", element:<ProtectedRoute> <Home/> </ProtectedRoute>},
    {path:"cart" , element:<ProtectedRoute> <Cart/> </ProtectedRoute>},
    {path:"products" , element:<ProtectedRoute> <Products/> </ProtectedRoute>},
    {path:"categories" , element:<ProtectedRoute> <Categories/> </ProtectedRoute>},
    {path:"brands" , element:<ProtectedRoute> <Brands/> </ProtectedRoute>},
    {path:"productDetails/:id" , element:<ProtectedRoute> <ProductDetails/> </ProtectedRoute>},
    {path:"allorders", element:<ProtectedRoute> <Orders/> </ProtectedRoute>},
    {path:"shippingAddress/:cartId", element:<ProtectedRoute> <Address/> </ProtectedRoute>},

    {path:"login", element:<ProtectedLog> <Login/> </ProtectedLog>},
    {index:true , element:<ProtectedLog> <Register/> </ProtectedLog>},
    
    {path:"*" , element:<Notfound/>}
  ]} 
])

export default function App() {

  const queryClient = new QueryClient(); // ha5od nos5a mn l QueryClient 

  return <Provider store={store}>

    <QueryClientProvider client={queryClient}> 

      <AuthContextProvider>

        <CartContextProvider>

          <RouterProvider router={routers}></RouterProvider>
          
        </CartContextProvider>

      </AuthContextProvider> 

      <ReactQueryDevtools position='bottom-right'/>

    </QueryClientProvider>

  </Provider>
 
}

