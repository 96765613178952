import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    counter : 0 
}

let counterSlice = createSlice({

    name : 'counter',

    initialState : initialState , 

    reducers : {
        increase : (state)=>{
            state.counter ++ 
        },
        decrease : (state)=>{
            state.counter --
        } 
    }
})

export let counterReducer = counterSlice.reducer ;
export let counterActions = counterSlice.actions ;