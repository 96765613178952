import React, { useContext, useEffect } from 'react'
import pic from "../../images/cartempty.png"
import axios from 'axios'
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { cartContext } from '../../Contexts/CartContext';

export default function Cart() {

  let [cart , setCart] = useState({});
  let [loadingIcon , setLoadingIcon] = useState(true); 
  let [emptyCart , setEmptyCart] = useState(true); // di m3mola 3shan lo l user lesa fl awl w mst5dmsh l cart 5als
  let [cartItems , setCartItems] = useState(0);
  let navigate = useNavigate();
  let [cartId , setCartId] = useState(""); // 3shan a5zn feha l cart id w ab3to fl url b3d keda 

  const {setCartItems : setCartItemsNew} = useContext(cartContext); // hena hya mwgoda abl keda f ht3ml conflict f lazm hna a3ml 7aga asmha (elios)


  function checkOut(){
    navigate("/shippingAddress/"+cartId)
  }

  async function updateCount(productId,count){
    if(count===0){ // lo el count = zero yb2a hwa 3awz y3ml remove lo msh zero yb2a 3awz y3ml update
      deleteProduct(productId);
    }
    else{
      let request = await axios.put(`https://ecommerce.routemisr.com/api/v1/cart/${productId}`,{count:count},{headers:
      {token:localStorage.getItem("userToken")}});
      console.log(request.data);
      setCart(request.data); // 5znt l data f variable l cart w 3shan y3ml re-render
    }
  }

  async function deleteProduct(productId){
    Swal.fire({
      title: "Are you sure?",
      text: "This product will be deleted from your cart!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#878c16",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        let request = await axios.delete(`https://ecommerce.routemisr.com/api/v1/cart/${productId}`,{headers:
        {token:localStorage.getItem("userToken")}});
        console.log(request.data);
        setCart(request.data); // 5znt l data f variable l cart w 3shan y3ml re-render
        setCartItems(request.data.numOfCartItems);
        setCartItemsNew(request.data.numOfCartItems);
        
        Swal.fire({
          title: "Deleted!",
          text: "Product has been deleted.",
          icon: "success"
        });
      }
    });

  }

  async function clearCart(){
    Swal.fire({
      title: "Are you sure?",
      text: "All products will be deleted from your cart!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#878c16",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        let request = await axios.delete(`https://ecommerce.routemisr.com/api/v1/cart`,{headers:
        {token:localStorage.getItem("userToken")}});
        console.log(request.data);
        setEmptyCart(true);
        setCartItemsNew(0);

        Swal.fire({
          title: "Deleted!",
          text: "All products have been deleted.",
          icon: "success"
        });
      }
    });

  }

  async function getUserCart(){
    setLoadingIcon(true);
    try {
      let request = await axios.get('https://ecommerce.routemisr.com/api/v1/cart',{headers:
      {token:localStorage.getItem("userToken")}});
      console.log(request.data);
      setCart(request.data); // 5znt l data f variable l cart
      setCartItems(request.data.numOfCartItems);
      setEmptyCart(false)
      setCartId(request.data.data._id)
    } catch (error) {
      console.log(error.response.data.message);
      setEmptyCart(true)
    }
    setLoadingIcon(false);
  }

  useEffect(()=>{
    getUserCart()
  },[]); // component did mount a7sn mkan anadi l api feh 

  return<>

    {loadingIcon?<div className='my-5 py-5 justify-content-center align-items-center d-flex'>
      <i className="fa-solid fa-spinner fa-spin text-main fa-3x"></i>
    </div>
    :
    <>
    {emptyCart || cartItems === 0 ?<div className='text-center'>
    <img src={pic} alt="emptyCart" width={355} className='my-3'/></div>
    :
    <div className='parent my-5'>

    {cart.data?.products.map((el)=>

    <div key={el._id} className='row shadow rounded-3 align-items-center p-0 m-0 mb-3 gx-5'>
      <div className='col-md-2 p-0'>
        <img src={el.product.imageCover} alt="productImage" className='w-100'/>
      </div>

      <div className='col-md-7'>
        <h2 className='mt-2'>{el.product.title.split(" ").splice(0,2).join(" ")}</h2>
        <h5 className='text-main mt-2'>{el.product.category.name}</h5>
        <p>{el.price} EGP</p>
        <p className='mt-3'>Total Price : {el.price * el.count} EGP</p>
      </div>

      <div className='col-md-3 text-center pb-3'>
       <div className="d-flex align-items-center justify-content-center justify-content-evenly">
         <button onClick={()=>updateCount(el.product._id,el.count-1)} className='btn bg-main text-white fs-5'>-</button>
         <span className='fs-4'>{el.count}</span>
         <button onClick={()=>updateCount(el.product._id,el.count+1)} className='btn bg-main text-white fs-5'>+</button>
        </div>
        <button onClick={()=>deleteProduct(el.product._id)} className='btn btn-outline-danger mt-3'>
          Delete Product<i className="fa-solid fa-trash ps-2"></i>
        </button>
      </div>
    </div>)}

    <div className='d-flex justify-content-between align-items-center mt-5'>
      <p className='fw-bolder p-0 m-0 fs-5'>Total Cart Price : {cart.data?.totalCartPrice} EGP</p>
      <div>
      <button onClick={()=>clearCart()} className='btn bg-danger text-white me-2 mb-2'>Clear Cart</button>
      <button onClick={checkOut} className='btn bg-main text-white mb-2'>Check Out</button> 
      </div>       
    </div>

    </div>}

    </>}
 
  </>
 
}
