import React from 'react'
import pic1 from "../../images/visa.png"
import pic2 from "../../images/mastercard.png"
import pic3 from "../../images/amazon.png"
import pic4 from "../../images/appstore.png"
import pic5 from "../../images/googlestore.png"
import { Link } from 'react-router-dom'

export default function Footer() {

  return <>

  <footer className="bg-main-light py-5">
    <div className="container">
        <h4>Get the Frech Cart App</h4>
        <p>We will send you a link, open it on your phone to download the app.</p>
        <div className="d-flex justify-content-center align-items-center">
            <div className="col-sm-10">
                <input type="email" className="form-control py-2" placeholder="Email ..." />
            </div>
            <div className="col-sm-2 ps-3">
                <button className="btn w-100 bg-main text-white">Share App Link</button>
            </div>
        </div>
        <div className="line border-bottom border-2 my-4"></div>

        <div className='d-flex justify-content-center align-items-center justify-content-between p-0 m-0 flex-wrap gap-3'>

          <div className='d-flex justify-content-center align-items-center'>
            <h5 className='p-0 m-0 me-2'>Payments Partners</h5>
            <img className='p-0 m-0 me-2'src={pic1} width={55} alt="visaImage"/>
            <img className='p-0 m-0 me-2 pt-2'src={pic3} width={95} alt="amazonImage"/>
            <img className='p-0 m-0'src={pic2} width={55} alt="masterCardImage"/>
          </div>

          <div className='d-flex justify-content-center align-items-center'>
            <h5 className='p-0 m-0 me-2'>Get Deliveries With Fresh Cart</h5>
            <Link to="https://www.apple.com/eg/app-store/" target="_blank">
              <img className='p-0 m-0 me-2'src={pic4} width={85} alt="appStoreImage"/>
            </Link>
            <Link to="https://play.google.com/store/games?hl=en&gl=US" target="_blank">
              <img className='p-0 m-0'src={pic5} width={95} alt="playStoreImage"/>
            </Link>
          </div>
        </div>

        <div className="line border-bottom border-2 mt-4 mb-0"></div>
    </div>
  </footer>

  
  </>

}
