import React from 'react'
import CategoriesSlider from '../CategoriesSlider/CategoriesSlider'

export default function Categories() {
  
  return<>
    <CategoriesSlider></CategoriesSlider>
    <br/>
    <br/>
  </>
    
  
}
