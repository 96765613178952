import React, { useContext } from 'react'
import { authContext } from '../../Contexts/AuthContext';
import { Navigate } from 'react-router-dom';

export default function ProtectedLog({children}){

 const {userIsLoggedIn} = useContext(authContext); // destruct l data l fl context l ana 3mlto

 return <>
 
    {userIsLoggedIn? <Navigate to={"/home"}/> : children }
    {/* lo el user loggedin w by7awl yro7 ll login aw register hyrg3o ll home , lo msh logged in hwdeh 3ady */}
  
  </>
}
