import React from 'react'
import pic from "../../images/error.svg"


export default function Notfound() {
  return <>
  
    <div className='text-center pt-5 mb-5'>

      <img src={pic} alt="error 404"/>

    </div>

  </>
}
